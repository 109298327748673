import { PageHeader } from '@ant-design/pro-components';
import { Button, Col, Divider, Form, Input, Row, Select, Table, Tag, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAsync, useAsyncFn } from 'react-use';
import React from 'react';
import { ProductProjectListResponseModel } from '@uniquegood/realworld-admin-interface/dist';
import { ColumnsType } from 'antd/es/table';
import DefaultLayout from '@src/components/DefaultLayout';
import { getLocalStorage } from '@src/utils/localStorage';
import { productApi, projectApi } from '@src/apis/admin';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

export default function AdvertisementProjectList() {
  const accessToken = getLocalStorage('token');

  const [tempList, setTempList] = React.useState<{ id: string; name: string }[]>();
  const [input, setInput] = React.useState('');

  const projectDataApi = useAsync(async () => {
    const preRequestData = await projectApi.getOriginalProjects('', 1, 1, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    const requestData = await projectApi.getOriginalProjects(
      '',
      1,
      preRequestData.data.data.count,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return requestData.data.data;
  }, []);

  const [adFreeProjectDataApi, fetchAdFreeProjectDataApi] = useAsyncFn(async () => {
    const { data } = await productApi.getAdvertisementProducts(false, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return data.data;
  }, []);

  const handleChange = (value: string) => {
    const parsedData = JSON.parse(value);

    setTempList((prev) => {
      if (!prev) return [parsedData];

      if (prev.filter((item) => item.id === parsedData.id).length === 0) {
        return [...prev, parsedData];
      }

      return prev;
    });
  };
  const handleAddClick = async () => {
    try {
      await productApi.editProductIsViewAd(
        {
          projectIds: tempList && tempList.length > 0 ? tempList.map((item) => item.id) : null,
          isViewAd: false
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      fetchAdFreeProjectDataApi();
      message.success('광고 시청 여부 변경 완료되었습니다.');
      setTempList(undefined);
    } catch (e) {
      console.error(e);
      message.success('오류가 발생해 시청 여부 변경에 실패했습니다.');
    }
  };

  const handleRemoveClick = async (projectId: string | null | undefined) => {
    try {
      if (typeof projectId !== 'string') return;

      await productApi.editProductIsViewAd(
        {
          projectIds: [projectId],
          isViewAd: true
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      fetchAdFreeProjectDataApi();
      message.success('광고 시청 여부 변경 완료되었습니다.');
    } catch (e) {
      console.error(e);
      message.success('오류가 발생해 시청 여부 변경에 실패했습니다.');
    }
  };

  const adFreeProjectColumns: ColumnsType<ProductProjectListResponseModel> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '프로젝트 명'
    },
    {
      key: 'projectId',
      dataIndex: 'projectId',
      title: '프로젝트 ID'
    },
    {
      key: 'action',
      title: '동작',
      render: (_, record) => (
        <Button danger onClick={() => handleRemoveClick(record.projectId)}>
          삭제
        </Button>
      )
    }
  ];

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  React.useEffect(() => {
    fetchAdFreeProjectDataApi();
  }, [fetchAdFreeProjectDataApi]);

  return (
    <DefaultLayout>
      <PageHeader
        title="게임 광고 관리"
        subTitle="각 게임의 광고 시청 여부를 설정합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        <Form style={{ width: '100%' }}>
          <Row style={{ width: '50%', marginBottom: '16px' }}>
            <Col flex={1}>
              <Form.Item label="프로젝트 검색" style={{ margin: 0 }}>
                <Select
                  showSearch
                  placeholder="광고 OFF할 게임을 검색해보세요."
                  options={projectDataApi.value?.data?.map((item) => ({
                    key: item.id,
                    value: JSON.stringify({
                      id: item.id,
                      name: item.name
                    }),
                    label: item.name
                  }))}
                  filterOption={(value, option) =>
                    option
                      ? JSON.parse(option.value)
                          .name.replaceAll(' ', '')
                          .includes(value.replaceAll(' ', ''))
                      : false
                  }
                  onSelect={handleChange}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          {tempList && tempList.length > 0 && (
            <Row key="test2" gutter={16} style={{ width: '50%', marginBottom: '16px' }}>
              <Col flex={1}>
                <Select
                  mode="multiple"
                  showSearch={false}
                  notFoundContent={null}
                  tagRender={tagRender}
                  onChange={(value) =>
                    setTempList((prev) =>
                      prev?.filter((item) => (value as unknown as string[]).includes(item.id))
                    )
                  }
                  value={tempList?.map((item) => ({ value: item.id, label: item.name }))}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col>
                <Button onClick={handleAddClick} type="primary">
                  추가하기
                </Button>
              </Col>
            </Row>
          )}
          <Divider />
          <Row key="test3" style={{ width: '50%', marginBottom: '16px' }}>
            <Input
              onChange={(e) => setInput(e.target.value)}
              placeholder="광고 OFF 프로젝트 검색"
            />
          </Row>
        </Form>

        <Table
          rowKey="id"
          dataSource={adFreeProjectDataApi.value?.filter((item) =>
            item.name?.replaceAll(' ', '').includes(input)
          )}
          columns={adFreeProjectColumns}
          loading={adFreeProjectDataApi.loading}
          pagination={{
            pageSize: 10000
          }}
        />
      </Content>
    </DefaultLayout>
  );
}
